import L from "leaflet";

export default function() {
    setUpMap()
}

function setUpMap() {
    const buttons = $(".btn-link")

    let map = L.map('leaflet-map').setView([37.9506, 23.6805], 12)

    L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
        attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
        maxZoom: 18,
        id: 'mapbox/streets-v11',
        tileSize: 512,
        zoomOffset: -1,
        accessToken: 'pk.eyJ1IjoiYW5kcnNlaXQiLCJhIjoiY2t6Mmpxanl0MXd2OTJxcWw1eGRvMjNqOCJ9.anc2p9XwCOA0jvH0_KwUHA'
    }).addTo(map);

    $(buttons).each(function() {
        let circle = L.circle($(this).data('latlng'), {
            color: 'red',
            fillColor: '#f03',
            fillOpacity: 0.5,
            radius: 50
        }).addTo(map)

        circle.bindPopup($(this).text())

        map.setZoom(20)
    })




    buttons.on('click', function() {
        map.flyTo($(this).data('latlng'))
    })
}
