import produceErrorAlert from "../../helpers/error_producer";
import {createTableRow, createLink, createHiddenInput} from "../../modules/createElements";

const CONTAINER_ID = '.collapsible-menu'
let toggleOn = false

export default function initializeSitePvsIndex() {
    addMultiLCAListener()
    _addChangeTableListeners()

    _addBetaAnalysisListener()
    _addLoadMoreListener()
    _addShowLessListener()
};

function _addLoadMoreListener() {
    const loadButton = $('#load-more-projects')
    loadButton.on('click', function () {
        const currentViewRows = $('#site-pvs-table table tbody tr').length
        $.ajax({
            url: '/b/load_more_site_pvs',
            data: {
                current_view_rows: currentViewRows,
            },
            method: 'GET',
            dataType: 'json',
            success: function (response, status, object) {
                if (status === 'success') {
                    addSitePvRows(response['site_pvs'])
                }
            }
        });
    })
}

function addSitePvRows (sitePvs) {
    const tableBody = $('#site-pvs-table tbody')
    const keys = Object.keys(sitePvs)
    for (let i = 0; i < keys.length; i++) {
        const sitePv = sitePvs[i]

        let actionsContainer = document.createElement('span')
        $(actionsContainer).append(createLink('Edit', sitePv['edit'], { class: 'bg-warning text-dark p-1 rounded' }))
        $(actionsContainer).append(' ')
        $(actionsContainer).append(createLink('Destroy', sitePv['destroy'], {
            class: 'bg-danger text-light p-1 rounded',
            data: { confirm: 'Are you sure?' }
        }))

        let analysisContainer = document.createElement('span')
        let analysisDiv = document.createElement('div')
        $(analysisDiv).css({ display: 'inline-block' })
        $(analysisDiv).append(_createAnalysisAction('LCA', sitePv['analysisParams']))
        $(analysisContainer).append(analysisDiv)

        $(analysisContainer).append(' ')

        analysisDiv = document.createElement('div')
        $(analysisDiv).css({ display: 'inline-block' })
        $(analysisDiv).append(_createAnalysisAction('LCC', sitePv['analysisParams']))
        $(analysisContainer).append(analysisDiv)

        let investmentPlan;
        if (sitePv['investmentName'] !== '-') investmentPlan = createLink(sitePv['investmentName'], sitePv['investmentPath'])
        else investmentPlan = '-'

        let disposalPlan
        if(sitePv['disposalName'] !== '-') disposalPlan = createLink(sitePv['disposalName'], sitePv['disposalPath'])
        else disposalPlan = '-'

        let dotColor = 'red'
        if (sitePv['sensorsState']) dotColor = 'green'

        const sitePvRow = createTableRow(
            '<span class="dot-' + dotColor + '"></span>' + sitePv['id'], sitePv['site'],
            createLink(sitePv['electricalName'], sitePv['electricalPath']),
            createLink(sitePv['thermalName'], sitePv['thermalPath']),
            investmentPlan,
            disposalPlan,
            actionsContainer,
            // analysisContainer
            _createExportDemandButton(sitePv['id'])
        )

        const analysisTd = $(sitePvRow).find('td:last')
        analysisTd.addClass('beta-analysis')
        if (!toggleOn) analysisTd.css({ display: 'none' })

        $(tableBody).append(sitePvRow)
    }
}

function _createExportDemandButton(sitePvId) {
    let button = document.createElement('button')
    button.type = 'button'
    $(button).data('href', `/site_pvs/${sitePvId}/export_demand_profile`)
    $(button).addClass('btn btn-sm btn-info')
    $(button).text('Export Demand')

    $(button).on('click', function(e) {
        e.preventDefault()
        window.open($(this).data('href'))
        window.focus()
    })

    return button
}

function _createAnalysisAction (analysis, params) {
    const lcaAction = document.createElement('form')
    const lcaParams = params
    if (analysis === 'LCA') lcaAction.action = params['lcaPath']
    else if (analysis === 'LCC') lcaAction.action = params['lccPath']
    lcaAction.method = 'get'
    lcaAction.classList = 'button_to'
    const lcaButton = document.createElement('input')
    if (analysis === 'LCA') lcaButton.classList = 'btn-sm btn-primary'
    else lcaButton.classList = 'btn-sm btn-success'
    lcaButton.type = 'submit'
    lcaButton.value = analysis

    $(lcaAction).append(lcaButton)
    $(lcaAction).append(createHiddenInput('', 'action_is', lcaParams['action_is']))
    $(lcaAction).append(createHiddenInput('', 'electrical_id', lcaParams['electrical_id']))
    $(lcaAction).append(createHiddenInput('', 'thermal_id', lcaParams['thermal_id']))
    $(lcaAction).append(createHiddenInput('', 'investment_plan_id', lcaParams['investment_plan_id']))
    $(lcaAction).append(createHiddenInput('', 'site_id', lcaParams['site_id']))
    $(lcaAction).append(createHiddenInput('', 'site_name', lcaParams['site_name']))

    return lcaAction
}

function _addShowLessListener () {
    const hideButton = $('#show-less-projects')
    hideButton.on('click', function () {

        const currentProjects = $('#site-pvs-table tbody tr').length

        if (currentProjects > 10) {
            const rowsToDelete = $(`#site-pvs-table tbody tr:gt(${currentProjects - 10})`)
            rowsToDelete.remove()
        }
    })
}

function _addBetaAnalysisListener() {
    const selector = '.beta-analysis'
    let rows = $(selector)
    rows.hide()
    const toggleButton = $('#toggle-beta-analysis')
    toggleButton.on('click', function () {
        rows = $(selector)
        rows.toggle()
        toggleOn = !toggleOn
    })
}

function addMultiLCAListener() {
    $("#multi-lca-form, #multi-lcc-form").on('submit', function (e) {
        e.preventDefault();
        const formIdStr = "#" + e.target.id;
        console.log(e.target);
        const checkboxesContainer = $(`${formIdStr} #comparison-select`).siblings()[0];
        const checked = $(checkboxesContainer).find(".sol-checkbox:checked");
        if (checked.length == 0) {
            if ($(".alert-includes").length) {
                $(`${formIdStr}`).children(':last').remove();
                console.log('what are you doing');
            }
            if (!$(".alert-empty").length) $(e.target).append(produceErrorAlert("Please select comparison scenarios", "alert-empty"));
        } else {
            const baselineId = $(`${formIdStr} .sol-radio:checked`).val();
            const comparisonIds = $(`${formIdStr} .sol-checkbox:checked`).map(function () {
                return $(this).val();
            });
            if (comparisonIds.toArray().includes(baselineId)) {
                if ($(".alert-empty").length) $(`${formIdStr}`).children(':last').remove();
                if (!$(".alert-includes").length) $(e.target).append(produceErrorAlert("Comparison list contains baseline", "alert-includes"));
            } else e.target.submit();
        }
    });
}

// export this into a module because it is widely used
function _addChangeTableListeners() {
    const pages = {
        "building": $('#site-pvs-table'),
        "district": $('#district-projects-table')
    }

    // initialization
    $('#show-building-page').prop('checked', true)
    _showPage(pages, 'building')
    _showMenu('building')

    $('input[type=radio]').on('click', function () {
        if (this.checked) _showPage(pages, $(this).val())
        _showMenu($(this).val())
    })
}

function _showPage(pages, selected) {
    pages[selected].show()
    for (let p in pages) if (p !== selected) pages[p].hide();
}

function _showMenu(selected) {
    $(`${CONTAINER_ID} .list-group :not(.${selected}-li)[class*=li]`).hide();
    $(`${CONTAINER_ID} .list-group .${selected}-li`).show();
}
