import produceErrorAlert from "../../helpers/error_producer";
import flatpickr from "flatpickr";

let cachedSitePvs = {}
let isCurrentProjectDual = []

export default function () {

    const datePickers = flatpickr("#datepickers-container .datepicker", {
        mode: "range",
        dateFormat: "d-m-Y",
        inline: true,
    });

    // on reload change the value in the project select because it keeps the last one
    // even though the server sends another project
    $('form')[0].reset();

    datePickers.forEach(e => e.redraw())

    $('button.lcc_submit, button.lca_submit').on('click', function () {

        const pressedButton = $(this);
        if (pressedButton.hasClass('lca_submit')) {
            pickDatesAndSubmit(pressedButton, 'lca');
        } else if (pressedButton.hasClass('lcc_submit')) {
            pickDatesAndSubmit(pressedButton, 'lcc');
        }

        pressedButton.blur();
    });

    _addChangeProjectListener()
    _setInitialDates()

    $('button.submit_date').each(function (i, btn) {
        $(btn).on('click', function () {
            // check if there are two pickers
            const container = $(this).parentsUntil('.container').last();
            const datepickers = $(container).find('.datepicker');
            const errorArea = $(container).parent();
            const parentForm = $(this).parents('form:first');
            let dates = {};

            $(datepickers).each(function (i, datepicker) {
                const range = $(datepicker).val().split(" to ");
                const dpType = $(datepicker).data('compare');
                dates[dpType] = range;
            });
            if ((dates['base'] && dates['base'].length !== 2) || (dates['var'] && dates['var'].length !== 2)) {
                if (!errorArea.find('.alert-date').length) errorArea.append(produceErrorAlert("Wrong date range!", "alert-date"));
            } else {
                parentForm.append('<input type="hidden" name="var[from]" value="' + dates['var'][0] + '" />');
                parentForm.append('<input type="hidden" name="var[to]" value="' + dates['var'][1] + '" />');
                if (dates['base']) {
                    parentForm.append('<input type="hidden" name="base[from]" value="' + dates['base'][0] + '" />');
                    parentForm.append('<input type="hidden" name="base[to]" value="' + dates['base'][1] + '" />');
                }

                parentForm.submit();
            }

            $(btn.blur());
        });
    });
  }


function _setInitialDates() {
    const projectId = $("#site_pv_id").val()
    _fetchProjectDates(projectId)
}

function pickDatesAndSubmit(btn, action) {
    const container = $('#datepickers-container');
    const datepickers = $(container).find('.datepicker');
    const errorArea = $(datepickers).parent();
    const parentForm = $(datepickers).parents('form:first');
    let dates = {};

    $(datepickers).each(function (i, datepicker) {
        const range = $(datepicker).val().split(" to ");
        const dpType = $(datepicker).data('compare');
        dates[dpType] = range;
    });

    // added to prevent an error
    if (!isCurrentProjectDual)
        delete dates.base

    if ((dates['base'] && dates['base'].length !== 2) || (dates['var'] && dates['var'].length !== 2)) {
        if (!errorArea.find('.alert-date').length) errorArea.append(produceErrorAlert("Wrong date range!", "alert-date"));
    } else {
        parentForm.append('<input type="hidden" name="var[from]" value="' + dates['var'][0] + '" />');
        parentForm.append('<input type="hidden" name="var[to]" value="' + dates['var'][1] + '" />');
        parentForm.append('<input type="hidden" name="action_is" value="' + action + '" />');
        if (dates['base']) {
            parentForm.append('<input type="hidden" name="base[from]" value="' + dates['base'][0] + '" />');
            parentForm.append('<input type="hidden" name="base[to]" value="' + dates['base'][1] + '" />');
        }

        parentForm.submit();
    }
}

function _addChangeProjectListener () {
    $('#site_pv_id').on('change', function (event) {
        _onProjectChangeListener(event.target.value)
    })
}

// send ajax request to receive the selected site_pv's data
// also caches the received site_pvs into a variable
function _onProjectChangeListener (projectId) {
    const cachedProject = cachedSitePvs[projectId]
    if (cachedProject) {
        _onProjectChange(cachedProject)
    } else {
        _fetchProjectDates(projectId)
    }
}

function _fetchProjectDates(projectId) {
    const url = `/b/site_pvs/${projectId}/lca_dynamic_show`
    $.ajax({
        url,
        dataType: 'json',
        success: function (response, status, obj) {
            _onProjectChange(response)
        }
    })
}

// TODO: Rename this function. It is a set of actions that take place after project change. The project may be cached.
function _onProjectChange (sitePvJson) {

    // filter null dates and parse string dates to Date
    let varDates = _filteredDates(sitePvJson.dates.var)
    varDates = _stringDatesToDate(varDates)

    let baseDates = _filteredDates(sitePvJson.dates.base)
    baseDates = _stringDatesToDate(baseDates)

    _updatePickersAvailableDates(varDates, baseDates)

    // 2. Update dates help text below the input
    _updateDatesHelpText(varDates, baseDates)

    // update the hidden project id input
    _updateProjectIdHiddenInput(sitePvJson.projectId)

    // change the project info (names)
    _updateProjectInfo(sitePvJson)

    // add the project to the cache if it doesn't already exist
    if (!cachedSitePvs[sitePvJson.projectId])
        cachedSitePvs[sitePvJson.projectId] = sitePvJson

    // TODO: consider moving this in a functions which takes care of this stuff
    isCurrentProjectDual = sitePvJson.isDual
}

function _updatePickersAvailableDates (datesVar, datesBase) {
    // find datepickers and update the dates
    // hide base datepicker if only var exists
    const container = $('#datepickers-container');
    const datepickers = $(container).find('.datepicker');

    let flatpickrDates = _prepareDatepickerAvailableDates(datesVar)
    flatpickr(datepickers[0], {
        mode: "range",
        dateFormat: "d-m-Y",
        inline: true,
        enable: flatpickrDates,
        defaultDate: [flatpickrDates[0].from, flatpickrDates[0].to]
    })

    const baseDatepickerContainer = $(datepickers[1]).parents('.col-auto')
    if (datesBase.length === 2) {
        baseDatepickerContainer.show()
        flatpickrDates = _prepareDatepickerAvailableDates(datesBase)
        flatpickr(datepickers[1], {
            mode: "range",
            dateFormat: "d-m-Y",
            inline: true,
            enable: flatpickrDates,
            defaultDate: [flatpickrDates[0].from, flatpickrDates[0].to]
        })
    } else {
        baseDatepickerContainer.hide();
    }
}

function _updateDatesHelpText (datesVar, datesBase) {
    const dateHelpTextsFrom = $('.available-dates-from')
    const dataHelpTextsTo = $('.available-dates-to')
    const dateRangeStringVar = _makeDaterangeString(datesVar)
    $(dateHelpTextsFrom[0]).text(dateRangeStringVar[0])
    $(dataHelpTextsTo[0]).text(dateRangeStringVar[1])

    if (datesBase.length === 2) {
        const dateRangeStringBase = _makeDaterangeString(datesBase)
        $(dateHelpTextsFrom[1]).text(dateRangeStringBase[0])
        $(dataHelpTextsTo[1]).text(dateRangeStringBase[1])
    }
}

function _updateProjectInfo (sitePvJson) {
    const infoTableCells = $('table td')

    const projectLocation = infoTableCells[1]
    $(projectLocation).text(sitePvJson.projectLocation)

    const electricalPlanName = infoTableCells[2]
    $(electricalPlanName).text(sitePvJson.electrical.name)

    const thermalPlanName = infoTableCells[3]
    $(thermalPlanName).text(sitePvJson.thermal.name)

}

function _updateProjectIdHiddenInput (projectId) {
    const projectIdInput = $('input[name=site_pv_id]')
    projectIdInput.val(projectId)
}

function _prepareDatepickerAvailableDates (varDates) {
    let enableDates = []
    enableDates.push(_fromToDateObject(varDates))
    return enableDates
}

// TODO: Not a very good name. Think about another one.
// This function returns a json in the form of { from: date_1, to: date_2 }
// for the flatpickr enable configuration
function _fromToDateObject (dates) {
    if (dates.length === 2) {
        return {
            from: `${dates[0].getDate()}-${dates[0].getMonth() + 1}-${dates[0].getFullYear()}`,
            to: `${dates[1].getDate()}-${dates[1].getMonth() + 1}-${dates[1].getFullYear()}`,
        }
    }
    return {}
}


function _makeDaterangeString (dates) {
    if (dates.length === 0) return ['N/A', 'N/A']
    const firstPart = `${dates[0].getDate()}/${dates[0].getMonth() + 1}/${dates[0].getFullYear()}`
    const secondPart = `${dates[1].getDate()}/${dates[1].getMonth() + 1}/${dates[1].getFullYear()}`

    return [firstPart, secondPart]
}

function _stringDatesToDate (dates) {
    for (let i = 0; i < dates.length; i++) {
        dates[i] = new Date(dates[i])
    }
    return dates
}

function _filteredDates (dates) {
    return dates.filter(d => d !== null)
}
