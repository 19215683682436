import initializeGeneralInformation from "./form_helpers/general_information";
import initializePricingForm from "./form_helpers/pricing";
import initializePositionForm from "./form_helpers/pvs_position_options";
import initializeConsumptionForm from "./form_helpers/consumption_details";
import pvComponentModal from './form_helpers/pv_components';
import pricingSchemeForm from "./pricing_scheme_form";

import ProductionFormHandler from './form_helpers/productionFormHandler'

export default function initializePvsForm() {

  const callbacks = {
    onInitialize: function(obj) {
      const modal = obj.modalForm
      const select = $(modal).find('select#material')
      const label = $(modal).find('label[for=installed_power]')
      $(select).on('change', function() {
        const value = this.value
        if(value.includes('zappaFacade')) {
          console.log('tin allazw')
          $(label).text('Surface (m2)')
        } else {
          $(label).text('Installed Power (KWp)')
        }
      })
    }
  }
  $('.production-container').each(function (index, element){
    new ProductionFormHandler(element, 'pv', $(element).data('gen_type'), callbacks);
  })
  initializeGeneralInformation();
  initializePricingForm();
  initializePositionForm();
  initializeConsumptionForm();
  pricingSchemeForm()
  // pvComponentModal();

  $(":submit[name=commit]").on('click', function (event) {
    event.preventDefault();
    sessionStorage.setItem('pvs', '[]');
    const form = $(event.target).closest('form');
    $(form).submit()
  })
};
