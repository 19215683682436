// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

//require("@rails/ujs").start()
//import "styles/application.scss"
//require("@rails/ujs").start()
import 'bootstrap';
//import "packs/styles/application.scss";
import PlotButtons from "./plot_buttons";
import init_multi_lca_lcc from "./multi_lca_lcc_form";
import flatpickr from "flatpickr";
// import "flatpickr/dist/flatpickr.min.css";
import autoHide from '../pages/auto_hide';

// import testPage from '../pages/home/test_page.js';

import slideShow from '../modules/slideShow';
import maps from '../pages/maps/index';
import initializeHomePlots from '../pages/home/plots';
import initializeNotifications from '../pages/notifications/index';
import notificationSubscription from '../pages/notifications/userSubscription';
import initializePvsForm from "../pages/pvs/pvs_form";
import initializeThermalsForm from "../pages/thermals/thermals_form";
import initializeInvestmentPlanForm from "../pages/investment_plans/form";
import initializeSitePvsIndex from "../pages/site_pvs/index.js";
import initializeDevicesForm from "../pages/devices/devices_form";
import initializeFillDataForm from "../pages/devices/fill_data_form";
import initializeDevicesIndex from "../pages/devices/index";
import initializeThermalDevices from "../pages/devices/thermal_devices";
import dragTableRow from "../modules/table_row_drag";
import initializePlots from "../pages/real_time_lca/index";
import lcaResults from "../pages/lca/results";
import lcaProjectSelect from '../pages/lca/project_select';
import automatedResults from '../pages/site_pvs/automated_results';
import districtProjects from '../pages/district/district_projects';
import districtAnalysis from '../pages/district/lca_analysis';
import loadBuildingScripts from '../pages/building/javascript_loader'
import loadDistrictFiles from '../pages/district/javascript_loader'

require("@rails/ujs").start()
require('turbolinks').start()
require("@rails/activestorage").start()
require("@rails/actioncable")

require("channels")
console.log('##In application.js !');
require("chart.js")

// From https://rubyyagi.com/how-to-use-bootstrap-and-jquery-in-rails-6-with-webpacker/
var jQuery = require('jquery')
require("cocoon")
// include jQuery in global and window scope (so you can access it globally)
// in your web browser, when you type $('.div'), it is actually refering to global.$('.div')
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

import "./sol";

const plotButtons = new PlotButtons();
document.addEventListener('turbolinks:load', () => {

  const pathname = window.location.pathname
  loadBuildingScripts(pathname)
  loadDistrictFiles(pathname)

  // if (window.location.pathname == "/test_page") testPage();
  if (window.location.pathname === "/overview") initializeHomePlots()
  else if (pathname.includes('graphs')) maps();
  else if (pathname.includes('notifications')) initializeNotifications();
  else if (pathname.includes('/thermal_devices')) initializeThermalDevices()
  else if ($("[id^='new_pv']").length || $("[id^='edit_pv']").length) initializePvsForm();
  else if ($("[id^='new_investment_plan']").length || $("[id^='edit_investment_plan']").length) initializeInvestmentPlanForm()
  else if ($("[id^='new_thermal']").length || $("[id^='edit_thermal']").length) {
    initializeThermalsForm();
    dragTableRow();
  } else if ($("#MultiLCA").length) initializeSitePvsIndex();
  else if ($("[id^='new_device']").length || $("[id^='edit_device']").length) initializeDevicesForm();
  // District project pages
  else if ($("[id^='new_district_project']").length || $("[id^='edit_district_project']").length) districtProjects({showSingle: true});
  else if ($("#district-lca-analysis").length > 0) {
    console.log('hello there ', $("#district-lca-analysis"))
    districtAnalysis()
  }
  else if (pathname === '/b/devices') initializeDevicesIndex();
  else if (window.location.pathname.includes('fill_data')) initializeFillDataForm();
  else if (window.location.pathname.includes('real_time_lca')) {
    const tokens = window.location.pathname.split('/');
    if (tokens.length === 3) initializePlots(tokens[2]);
    else initializePlots();
  } else if(pathname.includes('automated_results')) {
    automatedResults()
  } else if(pathname.includes('lcas/new')) {
    lcaProjectSelect()
  }
  else plotButtons.initButtons();



  lcaResults()
  // plotButtons.initButtons();

  $('[data-toggle="tooltip"]').tooltip()
  $('[data-toggle="popover"]').popover()

  init_multi_lca_lcc();

  $('.my-select, #baseline-select, #comparison-select').searchableOptionList(
    {
      useBracketParameters: true,
    }
  );

  $(".hBack").on("click", function(e) {
    e.preventDefault();
    window.history.back();
  });

  $('.file-download').on('click', function(e) {
    e.preventDefault()
    window.open($(this).data('href'))
    window.focus()
  })

  autoHide();
  slideShow();

  notificationSubscription();
})

//$(document).on('ready turbolinks:load', function() {
//    console.log('It works on each visit!');
//});
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
